import React, {useContext} from 'react';
import {graphql} from 'gatsby';
import {RichText} from 'prismic-reactjs';
import Layout from '@components/layout';
import SEO from '@components/seo';
import EdmusContext from '@components/application.provider';

export const query = graphql`
  {
    prismic {
      allLegalNoticess {
        edges {
          node {
            title
            content
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

const LegalNotices = (props: any) => {
  const {language} = useContext(EdmusContext);
  const {allLegalNoticess} = props.data.prismic;

  const doc: any = allLegalNoticess.edges.find(
    (docs: any) => docs.node._meta.lang.slice(0, 2) === language
  );

  if (!doc) return null;

  return (
    <Layout>
      <SEO title={RichText.asText(doc.node.title)} />
      <div className='uppercase text-orange text-center force-space-top-5'>
        {RichText.asText(doc.node.title)}
      </div>
      <div className='container sub-container'>
        <div className='columns'>
          <div className='column rich-content'>
            {RichText.render(doc.node.content)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LegalNotices;
